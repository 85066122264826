export function t(key,language){
  const transtalor = {
    main_title:{
      pt: 'O combo mais que perfeito para o seu desenvolvimento pessoal!',
      en: 'The more than perfect combo for your personal development!',
      es: '¡El combo más que perfecto para tu desarrollo personal!',
    },
    main_desc:{
      pt: 'Seja mais produtivo, crie e mantenha novos hábitos, estabeleça metas alcançáveis, descomplique suas finanças pessoais e se mantenha motivado no processo. Tudo isso com os nossos apps! Me diz se não é o combo perfeito para você!',
      en: "Be more productive, create and maintain new habits, set achievable goals, simplify your personal finances and stay motivated in the process. All this with our apps! Let me know if it's not the perfect combo for you!",
      es: 'Sea más productivo, cree y mantenga nuevos hábitos, establezca metas alcanzables, simplifique sus finanzas personales y manténgase motivado en el proceso. ¡Todo esto con nuestras aplicaciones! ¡Avísame si no es la combinación perfecta para ti!',
    },
    ours_apps:{
      pt: 'NOSSOS APLICATIVOS',
      en: 'OUR APPS',
      es: 'NUESTRAS APLICACIONES',
    },
    flynow_productivity:{
      pt: 'Flynow - Produtividade, Hábitos & Metas',
      en: 'Flynow - Tasks, Habits & Goals',
      es: 'Flynow - Productividad, Hábitos y Metas',
    },
    flynow_productivity_desc:{
      pt: "Imagine ser produtivo, criar e manter hábitos e estabelecer metas eficazes de maneira fácil. Com o Flynow - Produtividade, Hábitos & Metas você pode! Se você quer fazer seu dia render, criar hábitos que durem e estabelecer metas eficazes, esse é o app perfeito para você!",
      en: 'Imagine being productive, creating and maintaining habits, and setting effective goals the easy way. With Flynow - Productivity, Habits & Goals you can! If you want to make your day work, create habits that last and set effective goals, this is the perfect app for you!',
      es: 'Imagine ser productivo, crear y mantener hábitos y establecer metas efectivas de la manera más fácil. ¡Con Flynow - Productividad, Hábitos y Metas puedes! Si quieres hacer que tu día funcione, crear hábitos duraderos y establecer metas efectivas, ¡esta es la aplicación perfecta para ti!',
    },
    flynow_frases:{
      pt: 'Flynow - Frases de Motivação & Sabedoria',
      en: 'Flynow - Motivation and Wisdom Phrases',
      es: 'Flynow - Frases de Motivación y Sabiduría',
    },
    flynow_frases_desc:{
      pt: 'E se você pudesse encontrar motivação diária com apenas um clique? O Flynow - Frases de Motivação & Sabedoria é o app perfeito para te dar aquele empurrãozinho para começar o dia motivado, se inspirando com frases sobre empreendedorismo, disciplina, sabedoria e muito mais!',
      en: 'With Flynow - Motivation & Wisdom Phrases, you have a gallery of motivation, entrepreneurship, discipline, wisdom phrases. In addition, you can set times to receive notifications with phrases every day. Another differential is the possibility to share the phrase as text or image.',
      es: 'Con Flynow - Frases de Motivación y Sabiduría, tienes una galería de frases de motivación, emprendimiento, disciplina, sabiduría. Además, puedes establecer horarios para recibir notificaciones con frases todos los días. Otro diferencial es la posibilidad de compartir la frase como texto o imagen.',
    },
    see_more:{
      pt: 'Quero saber mais!',
      en: 'View more...',
      es: 'Ver más...',
    },

    //flynow produtividade
    flynow_produtividade:{
      pt: 'Flynow - Produtividade',
      en: 'Flynow - Productivity',
      es: 'Flynow - Productividad',
    },
    home_title:{
      pt: 'A sua produtividade a um download de distância',
      en: 'Your productivity is a download away',
      es: 'Tu productividad está a una descarga de distancia',
    },
    home_desc:{
      pt: 'O Flynow Produtividade é o aplicativo essencial para ajudar você a ser mais produtivo, alcançar seus objetivos e se tornar a melhor versão. Organize suas tarefas diárias, crie hábitos positivos e defina metas de forma eficiente, tudo em um só lugar.',
      en: 'With the Flynow - Productivity, you have access to the best methods of managing tasks, habits and goals to make your day work, create habits that really last and set effective goals to achieve your dreams and goals.',
      es: 'Con Flynow - Productividad, tienes acceso a los mejores métodos de gestión de tareas, hábitos y metas para que tu día funcione, crea hábitos que realmente perduren y establece metas efectivas para alcanzar tus sueños y metas.',
    },
    'why_use_our_app': {
      pt:'Por que usar o nosso app?',
      en:'Why use our app?',
      es:'¿Por qué usar nuestra aplicación?',
    },
    button_download_android: {
      pt: 'Baixar na Google Play',
      en: 'Get it on Google Play',
      es: 'Disponible en Google Play',
    },
    button_download_ios: {
      pt: 'Baixar na App Store',
      en: 'Download on App Store',
      es: 'Disponible en App Store',
    },
    button_download_web: {
      pt: 'Acessar Versão Web',
      en: 'Access Web Version',
      es: 'Acceder a la versión web',
    },
    button_download_extension: {
      pt: 'Acessar Extensão',
      en: 'Access Extension',
      es: 'Extensión de acceso',
    },
    button_version_premium: {
      pt: 'Versão Premium',
      en: 'Premium Version',
      es: 'Versión Prémium',
    },
    functionalities: {
      pt:'FUNCIONALIDADES',
      en:'FUNCTIONALITIES',
      es:'FUNCIONALIDADES',
    },
    manage_tasks: {
      pt:'Descomplique o gerenciamento de tarefas',
      en:'Uncomplicate task management',
      es:'Gestión de tareas sin complicaciones',
    },
    manage_tasks_desc: {
      pt:'Com o Flynow - Produtividade, você adiciona suas tarefas, compromissos e reuniões de maneira fácil e prática, além de classificar suas tarefas de acordo com a Tríade do Tempo, escolher categorias, elaborar checklists para acompanhar o seu progresso em determinada atividade, estabelecer horários e notificações para ser lembrado das tarefas e muito mais!',
      en:'With Flynow - Productivity, you can easily and conveniently add your tasks, appointments and meetings, as well as classify your tasks according to the Time Triad, choose categories, create checklists to track your progress in a given activity, establish schedules and notifications to be reminded of tasks and much more!',
      es:'Con Flynow - Productividad, puedes agregar de manera fácil y conveniente tus tareas, citas y reuniones, así como clasificar tus tareas según la Tríada de Tiempo, elegir categorías, crear listas de verificación para seguir tu progreso en una actividad determinada, establecer horarios y notificaciones para ser recordatorio de tareas y mucho más!',
    },
    manage_habits: {
      pt:'Facilite o processo de criação de hábitos',
      en:'Ease the habit-building process',
      es:'Facilite el proceso de creación de hábitos.',
    },
    manage_habits_desc: {
      pt:'Quer facilidade no processo de criação de hábitos? O Flynow - Produtividade te ajuda! Ao adicionar um hábito no app você define o Loop do Hábito dele (deixa, rotina e recompensa) e ainda obtém estatísticas sobre o seu desempenho naquele hábito.',
      en:'Want ease in the process of creating habits? Flynow - Productivity helps you! When you add a habit in the app, you define its Habit Loop (cue, routine and reward) and still get statistics on your performance in that habit.',
      es:'¿Quieres facilidad en el proceso de creación de hábitos? Flynow - ¡La productividad te ayuda! Cuando agrega un hábito en la aplicación, define su ciclo de hábitos (señal, rutina y recompensa) y aún obtiene estadísticas sobre su desempeño en ese hábito.',
    },
    manage_goals: {
      pt:'Estabeleça metas realmente alcançáveis',
      en:'Set really achievable goals',
      es:'Establece metas realmente alcanzables',
    },
    manage_goals_desc: {
      pt:'O Flynow - Produtividade também te ajuda a estabelecer metas que realmente são alcançadas. Utilizando da Metodologia SMART, no app você consegue definir metas de forma facilitada, além de escolher categorias para suas metas, relacionar hábitos e tarefas e ainda visualizar o seu progresso.',
      en:'Flynow - Productivity also helps you to set goals that are actually achieved. Using the SMART Methodology, in the app you can easily set goals, in addition to choosing categories for your goals, relating habits and tasks and also viewing your progress.',
      es:'Flynow: la productividad también lo ayuda a establecer objetivos que realmente se logran. Usando la Metodología SMART, en la aplicación puedes establecer metas fácilmente, además de elegir categorías para tus metas, relacionar hábitos y tareas y también ver tu progreso.',
    },
    statistics: {
      pt:'Use as estatísticas a seu favor',
      en:'Use statistics to your advantage',
      es:'Usa las estadísticas a tu favor',
    },
    statistics_desc: {
      pt:'Para completar, você ainda tem acesso ao seu percentual de atividades concluídas, estatísticas específicas para cada tarefa e hábito, total de pontos conquistados durante a semana, gráficos referentes à sua Tríade do Tempo e Roda da Vida, além de Relatórios Semanais e Mensais.',
      en:'To top it off, you still have access to your percentage of completed activities, specific statistics for each task and habit, total points earned during the week, graphs referring to your Triad of Time and Wheel of Life, in addition to Weekly and Monthly Reports.',
      es:'Como si fuera poco, aún tienes acceso a tu porcentaje de actividades completadas, estadísticas específicas para cada tarea y hábito, total de puntos ganados durante la semana, gráficos referentes a tu Tríada del Tiempo y Rueda de la Vida, además de Reportes Semanales y Mensuales .',
    },

    reports: {
      pt:'Receba Relatórios Diários, Semanais, Mensais e Anuais.',
      en:'Receive Daily, Weekly, Monthly and Annual Reports.',
      es:'Reciba informes diarios, semanales, mensuales y anuales.',
    },
    reports_desc: {
      pt:'Com os relatórios você consegue visualizar diversas estatísticas sobre o seu desempenho no período. Além de diversos gráficos para você compreender como pode evoluir.',
      en:'With the reports you can view various statistics about your performance over the period. In addition to several graphs for you to understand how you can evolve.',
      es:'Con los informes puede ver varias estadísticas sobre su desempeño durante el período. Además de varios gráficos para que entiendas cómo puedes evolucionar.',
    },
    web: {
      pt:'Gerencie suas tarefas também pelo navegador e extensão web',
      en:'Manage your tasks also through the browser and web extension',
      es:'Gestiona tus tareas también a través del navegador y la extensión web',
    },
    web_desc: {
      pt:'Tenha acesso às funcionalidades do app pelo computador e gerencie seus hábitos, metas e tarefas com a Plataforma Web e extensão do google chrome.',
      en:"Access the app's features on your computer and manage your habits, goals and tasks with the Web Platform and Google Chrome extension.",
      es:'Acceda a las funciones de la aplicación en su computadora y administre sus hábitos, objetivos y tareas con la plataforma web y la extensión de Google Chrome.',
    },
    
    modeview: {
      pt:'Diversos Modos de Visualização',
      en:'Various Viewing Modes',
      es:'Varios modos de visualización',
    },
    modeview_desc: {
      pt:'Tenha em mãos 10 diferentes modos de visualização: Pendências, Linha do Tempo, Lista e Agenda nas variações de 1 dia, 3 dias, Semana e Mês.',
      en:'Have 10 different viewing modes at hand: To Do, Timeline, List and Agenda in variations of 1 day, 3 days, Week and Month.',
      es:'Tenga a mano 10 modos de visualización diferentes: Tareas pendientes, Línea de tiempo, Lista y Agenda en variaciones de 1 día, 3 días, Semana y Mes.',
    },

    tools: {
      pt:'Ferramentas: Notas, Timer, Meta diária e Checklist',
      en:'Tools: Notes, Timer, Daily Goal and Checklist',
      es:'Herramientas: notas, cronómetro, objetivo diario y lista de verificación',
    },
    tools_desc: {
      pt:'Adicione Notas e Timer para cada frequência das atividades. Adicione metas diárias em seus hábitos e checklists diários em suas tarefas.',
      en:'Add Notes and Timer for each activity frequency. Add daily goals to your habits and daily checklists to your tasks.',
      es:'Agregue notas y temporizador para cada frecuencia de actividad. Agregue objetivos diarios a sus hábitos y listas de verificación diarias a sus tareas.',
    },

    gamification: {
      pt:'Gamificação: Diverta-se no processo',
      en:'Gamification: Have fun in the process',
      es:'Gamificación: Diviértete en el proceso',
    },
    gamification_desc: {
      pt:'E se você pudesse se divertir enquanto define tarefas, cria hábitos e metas? Com o Ranking Semanal, Mensal e Geral, você visualiza a sua colocação e de outros usuários do app e se motiva a ser cada vez mais produtivo.',
      en:'What if you could have fun while setting tasks, creating habits and goals? With the Weekly, Monthly and General Ranking, you can view your position and that of other app users and motivate yourself to be increasingly productive.',
      es:'¿Qué pasaría si pudieras divertirte mientras estableces tareas, creas hábitos y metas? Con el Ranking Semanal, Mensual y General podrás visualizar tu posición y la de otros usuarios de la app y motivarte para ser cada vez más productivo.',
    },
    ranking: {
      pt:'Acompanhe sua colocação em meio aos outros usuários',
      en:'Track your placement among other users',
      es:'Seguimiento de su ubicación entre otros usuarios',
    },
    ranking_desc: {
      pt:'E se você pudesse se divertir enquanto define tarefas, cria hábitos e metas? Com o Ranking Semanal, Mensal e Geral, você visualiza a sua colocação e de outros usuários do app e se motiva a ser cada vez mais produtivo!',
      en:'Flynow uses the concept of gamification to motivate and engage users in carrying out their activities. In addition, the application has the weekly, monthly and general ranking. In other words, the ranking shows the 30 users who earned the most points during the period referring to the type of ranking.',
      es:'Flynow utiliza el concepto de gamificación para motivar e involucrar a los usuarios en el desarrollo de sus actividades. Además, la aplicación tiene el ranking semanal, mensual y general. Es decir, el ranking muestra los 30 usuarios que más puntos obtuvieron durante el período referido al tipo de ranking.',
    },
    widget: {
      pt:'Marque as atividades como concluídas pela tela do celular',
      en:'Mark activities as completed from your mobile screen',
      es:'Marca actividades como completadas desde la pantalla de tu móvil',
    },
    widget_desc: {
      pt:'Com o Widget, você visualiza suas atividades do dia e pode concluí-las pela tela do celular, sem necessidade de abrir o aplicativo. Quer mais praticidade que isso?',
      en:'With the Widget, you can view your activities for the day and complete them from your cell phone screen, without having to open the application. Want more practicality than that?',
      es:'Con el Widget puedes visualizar tus actividades del día y completarlas desde la pantalla de tu celular, sin necesidad de abrir la aplicación. ¿Quieres más practicidad que eso?',
    },
    platform_web: {
      pt:'Gerencie suas tarefas também pelo navegador',
      en:'Manage your tasks also through the browser',
      es:'Gestiona tus tareas también a través del navegador',
    },
    platform_web_desc: {
      pt:'Tenha acesso às funcionalidades do app pelo computador e gerencie seus hábitos, metas e tarefas com a Plataforma Web.',
      en:'With the Web Platform it is possible to manage all your tasks, habits and goals also by browser. Also, the web platform has all the other functionalities present, not applicable.',
      es:'Con la Plataforma Web es posible gestionar todas sus tareas, hábitos y objetivos también a través del navegador. Además, la plataforma web tiene todas las demás características presentes en la aplicación.',
    },
    extension: {
      pt:'Conclua tarefas também pela extensão para navegador',
      en:'Complete tasks also via the browser extension',
      es:'Complete tareas también a través de la extensión del navegador',
    },
    extension_desc: {
      pt:'A extensão para navegador, disponível para o Google Chrome e Firefox, permite que você marque como concluídas suas tarefas, hábitos e metas de forma rápida e prática mesmo quando estiver trabalhando no seu notebook.',
      en:'The browser extension, available for Google Chrome and Firefox, allows you to quickly and conveniently mark your tasks, habits and goals as complete even when you are working on your laptop.',
      es:'La extensión del navegador, disponible para Google Chrome y Firefox, le permite marcar de manera rápida y conveniente sus tareas, hábitos y objetivos como completos, incluso cuando está trabajando en su computadora portátil.',
    },
    depositions:{
      pt:'O que os usuários falam do Flynow?',
      en:'What do users say about Flynow?',
      es:'¿Qué dicen los usuarios sobre Flynow?',
    },
    depo_1:{
      pt:'É simplesmente o melhor aplicativo de organização que você vive! Consigo organizar meio dia ou mais inteiro por ele! É fácil de usar, possui uma interface agradável e nos incentiva a cumprir nossos objetivos, além de nos ensinar como definir metas e classificar tarefas!',
      en:'It is simply the best organization app that you live! I manage to organize half a day or more inteiro for him! It is easy to use, has a nice interface and encourages us to meet our objectives, as well as teaching how to set goals and classify tasks!',
      es:'É simplismente o melhor app de organização que eu já tive! Consigo organizar meu dia e o mês inteiro por ele! ¡Ele é fácil de usar, tem uma interface linda e nos incentiva a cumplir con nuestros objetivos, além de ensinar como fazer metas e classificar as tarefas!',
    },
    depo_2:{
      pt:'De todos os apps de gestão de tarefas que já instalei pra testar e usar, esse de longe é o melhor!!! Ele superou todas as minhas expectativas, porque é bastante intuitivo, muitas ferramentas super úteis, tem lembrete de agendamentos, dá dicas para criamos hábitos saudáveis no dia a dia, e ainda é gratuíto!!! Excelente!!! 10 pros criadores!',
      en:"Of all the task management apps I've installed to test and use, this one is by far the best!!! It surpassed all my expectations, because it's very intuitive, lots of super useful tools, has appointment reminders, gives tips to create healthy habits in everyday life, and it's still free!!! Great!!! 10 for the creators!",
      es:'De todas las aplicaciones de administración de tareas que he instalado para probar y usar, ¡esta es, con mucho, la mejor! Superó todas mis expectativas, porque es muy intuitivo, muchas herramientas súper útiles, tiene recordatorios de citas, da consejos para crear hábitos saludables en la vida diaria, ¡y sigue siendo gratis! ¡¡¡Excelente!!! ¡10 para los creadores!',
    },
    depo_3:{
      pt:'Esse aplicativo trouxe muita clareza no meu dia. Consigo me organizar e cumprir minhas atividades com clareza. Parabéns aos Desenvolvedores.',
      en:'This app brought a lot of clarity to my day. I can organize myself and carry out my activities clearly. Congratulations to the Developers.',
      es:'Esta aplicación trajo mucha claridad a mi día. Puedo organizarme y realizar mis actividades con claridad. Felicitaciones a los desarrolladores.',
    },
    depo_4:{
      pt:'Que app é esse? Extremamente sensacional, dificilmente te dá a possibilidade de criar metas, objetivos e classifica - os elementos que te explica ou que dá exemplos, ajuda-me a não confundir. Que amor, você está derrotado.',
      en:'What app is that? Extremely sensational, it hardly gives you the possibility of creating goals, objectives and classifies -the elements explain or give you examples, it helps me not to confuse. What love, you are beaten.',
      es:'Que app é esse? Extremamente sensacional, ele não te dá apenas una posibilidad de criar as metas, objetivos e classifica-los ele te explica o que é da exemplos, isso me ajudou a não confundir. Que amor, estou apaixonada.',
    },
    depo_5:{
      pt:'Sem dúvidas, um dos melhores apps para quem quer aumentar a produtividade. Além de organizar o nosso dia e deixarmos mais produtivos, podemos ter acesso a um ranking de quem fez mais pontos. Muito Top!!!',
      en:'Without a doubt, one of the best apps for those who want to increase productivity. In addition to organizing our day and making us more productive, we can have access to a ranking of who scored the most points. Very cool!!!',
      es:'Sin duda, una de las mejores aplicaciones para quienes quieren aumentar la productividad. Además de organizar nuestro día y hacernos más productivos, podemos tener acceso a un ranking de quién obtuvo más puntos. ¡¡¡Muy genial!!!',
    },
    depo_6:{
      pt:'App maravilhoso, organizar todas as minhas tarefas, hábitos e metas, nunca mais esqueci de realizar uma tarefa pq ele sempre me lembra. Testei muitos outros apps mas esse foi o único que atendeu as minhas expectativas',
      en:"Wonderful app, I can organize all my tasks, habits and goals, I never forgot to perform a task because it always reminds me. I've tested many other apps but this was the only one that met my expectations.",
      es:'Maravillosa app, puedo organizar todas mis tareas, hábitos y metas, nunca me olvidé de realizar una tarea porque siempre me lo recuerda. Probé muchas otras aplicaciones, pero esta fue la única que cumplió con mis expectativas.',
    },
    depo_7:{
      pt:'O único aplicativo que atende às suas necessidades. Estou de luto contra a depressão e estou determinado a ter um rodízio básico, para começar, e o aplicativo vai me ajudar muito.',
      en:'The only app that caters to your needs. Mourning against depression and I am determined to have a basic rotation, to start, and the application will help me a lot.',
      es:'La única aplicación que mejor cubrió mis necesidades. Lucho contra la depresión y estoy decidido a tener una rutina básica para empezar, y la aplicación me ayudará mucho.',
    },

    flynow_finances: {
      pt: "Flynow - Finanças",
      en: "Flynow - Finances",
      es: "Flynow - Finanzas",
    },
    flynow_finances_main: {
      pt: "Simplifique sua vida financeira de uma vez por todas!",
      en: "Simplify your financial life once and for all!",
      es: "¡Simplifica tu vida financiera de una vez por todas!",
    },
    flynow_finances_desc: {
      pt: "Com o Flynow - Finanças Pessoais você simplifica sua vida financeira de uma vez por todas! Diga adeus ao processo chato de gerenciamento de finanças pessoais e tenha controle sobre seus gastos e orçamentos, estabeleça metas financeiras e obtenha estatísticas e gráficos sobre suas finanças, tudo em um lugar só!",
      en: "With Flynow - Personal Finance you simplify your financial life once and for all! Say goodbye to the boring process of managing personal finances and have control over your spending and budgets, set financial goals and get statistics and graphs about your finances, all in one place!",
      es: "¡Con Flynow - Personal Finance simplificas tu vida financiera de una vez por todas! Dile adiós al aburrido proceso de administrar tus finanzas personales y ten control sobre tus gastos y presupuestos, establece metas financieras y obtén estadísticas y gráficos sobre tus finanzas, ¡todo en un solo lugar!",
    },
    simplify_your_life:{
      pt:"Simplifique sua vida financeira",
      en:"Simplify your financial life",
      es:"Simplifica tu vida financiera",
    },
    simplify_your_life_desc:{
      pt:"Ao usar o Flynow - Finanças Pessoais, você controla seus gastos, separa seu dinheiro em carteiras, cria orçamentos para categorias e metas financeiras e muito mais! Sua vida financeira ficará muito mais simples depois disso.",
      en:"When using Flynow - Personal Finances, you control your expenses, separate your money into wallets, create budgets for financial categories and goals and much more! Your financial life will be much simpler after that.",
      es:"Al usar Flynow - Finanzas personales, usted controla sus gastos, separa su dinero en billeteras, crea presupuestos para categorías y objetivos financieros y ¡mucho más! Tu vida financiera será mucho más sencilla después de eso.",
    },
    incomes_expenses:{
      pt:"Tenha em mãos seus Gastos e Receitas",
      en:"Keep your expenses and income at hand",
      es:"Mantén tus gastos e ingresos a mano",
    },
    incomes_expenses_desc:{
      pt:"Na aba Transações, você visualiza todo seu histórico de gastos e receitas por um período de tempo (Mensal ou Anual). Além disso, você visualiza o balanço do período definido. Outro diferencial, é a possibilidade de filtrar os gastos e receitas por carteiras, categorias, tags, status ou pesquisar por uma palavra chave.",
      en:"In the Transactions tab, you can view your entire spending and income history for a period of time (Monthly or Annually). Additionally, you can view the balance sheet for the defined period. Another difference is the possibility of filtering expenses and income by portfolios, categories, tags, status or searching by a keyword.",
      es:"En la pestaña Transacciones, puede ver todo su historial de gastos e ingresos durante un período de tiempo (mensual o anual). Además, puede ver el balance del período definido. Otra diferencia es la posibilidad de filtrar gastos e ingresos por carteras, categorías, etiquetas, estado o buscar por palabra clave.",
    },
    statistics_finances:{
      pt:"Use estatísticas e gráficos para entender suas finanças",
      en:"Use statistics and graphs to understand your finances",
      es:"Utilice estadísticas y gráficos para comprender sus finanzas",
    },
    statistics_finances_desc:{
      pt:"Na tela Estatísticas, você tem acesso a estatísticas e gráficos de seus gastos, receitas, categorias, carteiras e tags. Dessa forma, você consegue visualizar a porcentagem de cada tipo de gasto e controlar sua vida financeira.",
      en:"On the Statistics screen, you have access to statistics and graphs of your expenses, income, categories, portfolios and tags. This way, you can view the percentage of each type of expense and control your financial life.",
      es:"En la pantalla de Estadísticas, tienes acceso a estadísticas y gráficos de tus gastos, ingresos, categorías, carteras y etiquetas. De esta manera podrás visualizar el porcentaje de cada tipo de gasto y controlar tu vida financiera.",
    },
    goals_finances:{
      pt:"Acompanhe suas metas financeiras",
      en:"Track your financial goals",
      es:"Realice un seguimiento de sus objetivos financieros",
    },
    goals_finances_desc:{
      pt:"No app você também pode criar metas financeiras com a função “Metas”, acompanhar suas metas de perto e até mesmo criar uma carteira de forma automática para ver o andamento da sua meta.",
      en:"In the app you can also create financial goals with the “Goals” function, monitor your goals closely and even automatically create a portfolio to see the progress of your goal.",
      es:"En la aplicación también puedes crear metas financieras con la función “Metas”, monitorear de cerca tus metas e incluso crear automáticamente una cartera para ver el progreso de tu meta.",
    },
statistics_models_finances: {
    pt: "Estatísticas especificas sobre cada carteira, categoria, tag e orçamento",
    en: "Specific statistics on each wallet, category, tag, and budget",
    es: "Estadísticas específicas sobre cada cartera, categoría, etiqueta y presupuesto",
  },
  statistics_models_finances_desc: {
    pt: "Ao visualizar os detalhes de cada carteira, categoria, tag e orçamento você tem acesso a diversas estatísticas especificas do item em detalhes.",
    en: "By viewing the details of each wallet, category, tag, and budget, you have access to various specific statistics of the item in detail.",
    es: "Al ver los detalles de cada cartera, categoría, etiqueta y presupuesto, tienes acceso a varias estadísticas específicas del artículo en detalle.",
  },
  categories_finances: {
    pt: "Gerencie suas Categorias de Gastos e Receitas.",
    en: "Manage your Spending and Income Categories.",
    es: "Gestiona tus Categorías de Gastos e Ingresos.",
  },
  categories_finances_desc: {
    pt: "As categorias te ajudam a entender de onde vem seus maiores ganhos e para onde vão seus gastos, para isso basta selecionar a categoria que se refere a cada transação de gasto ou receita.",
    en: "Categories help you understand where your biggest gains come from and where your expenses go. To do this, just select the category that refers to each spending or income transaction.",
    es: "Las categorías te ayudan a entender de dónde provienen tus mayores ganancias y a dónde van tus gastos. Para hacer esto, solo selecciona la categoría que se refiere a cada transacción de gasto o ingreso.",
  },
  web_finances: {
    pt: "Controle suas finanças também pelo Navegador",
    en: "Manage your finances through the Browser",
    es: "Controla tus finanzas también a través del Navegador",
  },
  web_finances_desc: {
    pt: "Tenha acesso às funcionalidades do app pelo computador e gerencie suas finanças, orçamentos, carteiras, etc.",
    en: "Access the app's features on your computer and manage your finances, budgets, wallets, etc.",
    es: "Accede a las funciones de la aplicación en tu computadora y gestiona tus finanzas, presupuestos, carteras, etc.",
  },
  }[key]

  if(!transtalor) return 'Invalid text';
  return transtalor[language]
}